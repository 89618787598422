import { Component, OnInit, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-showed-all-records-pagination-dropdown',
  templateUrl: './showed-all-records-pagination-dropdown.component.html',
  styleUrls: ['./showed-all-records-pagination-dropdown.component.scss']
})
export class ShowedAllRecordsPaginationDropdownComponent implements OnInit {
  itemsPerPage :any = 25;
  @Output() onChangeShowedRecordsEmitter = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
  }
  onChangePaination() {
    this.onChangeShowedRecordsEmitter.emit(this.itemsPerPage);
  }
}
