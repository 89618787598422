import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirmation-dialog-popup',
    templateUrl: './confirmation-dialog-popup.component.html',
    styleUrls: ['./confirmation-dialog-popup.component.scss']
})
export class ConfirmationDialogPopupComponent implements OnInit {
    message = '';
    ngbModal;

    @Output() approveConfirmDialog = new EventEmitter();
    @ViewChild('confirmationDialogPopup') confirmationDialogPopup: NgxSmartModalComponent;

    constructor(private _NgxSmartModalService: NgxSmartModalService, private activeModal: NgbActiveModal) { }

    ngOnInit() {
    }

    showPopup(message) {
        this.message = message;
        // this._NgxSmartModalService.get('confirmationDialogPopup').open();
        this.confirmationDialogPopup.open();
    }

    ok() {
        if (this.ngbModal) {
            this.activeModal.close();
        } else {
            this.approveConfirmDialog.emit();
            // this._NgxSmartModalService.get('confirmationDialogPopup').close();
            this.confirmationDialogPopup.close();
        }
    }
}
