import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    loadChildren: "./user/user.module#UserModule"
  },
  {
    path: "admin",
    loadChildren: "./admin/admin.module#AdminModule"
  },
  {
    path: "**",
    redirectTo: "/error/404"
  }
];

export const AppRoutingModule = RouterModule.forRoot(routes);
