import { BrowserModule } from "@angular/platform-browser";
import { NgModule , APP_INITIALIZER } from "@angular/core";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { SharedModule } from "./shared/shared.module";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { ClientHttpInterceptor } from "./utils/client-http-interceptor";
import { CustomValidators } from "./utils/custom-validators";
import { ToastrModule } from 'ng6-toastr-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatePickerOptions } from "./utils/ngx-date-picker-options";
import { CustomTableModule } from "./admin/components/custom-table/custom-table.module";
import { AppInitService } from "./app-init.service";
//import { ChartsModule } from 'ng2-charts';
export function init_app(appLoadService: AppInitService) {
  return () => appLoadService.init();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NgxPaginationModule,
    HttpClientModule,
    NgxSmartModalModule.forRoot(),
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: "modal-content",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn"
    }),
    ToastrModule.forRoot(),
    BrowserAnimationsModule ,
    CustomTableModule
   // ChartsModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientHttpInterceptor,
      multi: true
    },
    CustomValidators ,
    NgxDatePickerOptions,
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
