import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-showed-records-pagination-dropdown',
  templateUrl: './showed-records-pagination-dropdown.component.html',
  styleUrls: ['./showed-records-pagination-dropdown.component.scss']
})
export class ShowedRecordsPaginationDropdownComponent implements OnInit {
  itemsPerPage :any = 25;

  @Output() onChangeShowedRecordsEmitter = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  onChangePaination() {
    this.onChangeShowedRecordsEmitter.emit(this.itemsPerPage);
  }
}
