import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, args?: any): String {
    if (value == null) {
      return null;
    };
    var mydate = new Date(value);
    var month = mydate.getMonth() + 1;
    var day = mydate.getDate();
    var year = mydate.getFullYear();
    return (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;
  }

}
