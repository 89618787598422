import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'custom-table',
    templateUrl: './custom-table.component.html',
    styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent implements OnInit {

    @Input() headers = [];
    @Output() sortBy = new EventEmitter<any>();
    order: string = '';
    orderType: boolean = true;
    constructor() { }

    ngOnInit() {
        let defaultHeader = this.headers.filter(header => header.default);
        this.order = defaultHeader.length > 0 ? defaultHeader[0].value : this.headers[0].value;
    }

    orderBy(order) {
        if (!order) {
            return;
        }
        if (this.order === order) {
            this.orderType = !this.orderType;
        }
        this.order = order;

        this.sortBy.emit({ order: this.order, orderBy: this.orderType });
    }

    columnWidth(header) {
        if (header.width == null || header.width == undefined) { return 'unset'; }
        else {
            return header.width;
        }
    }
}
