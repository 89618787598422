import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCardNumber'
})
export class CreditCardNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value =value.toString();
    var replaceText = value.substring(4, value.length - 4);
    var x = '';
    for (let index = 0; index < replaceText.length; index++) {
    x +='x'
    }
    return  value.substring(0,4)+ x + value.substring(value.length - 4 ,value.length);
  }

}
